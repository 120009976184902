const vendorRoutes = [
  {
    path: '/team/',
    name: 'Index',
    redirect: {
      name: 'VendorDashboard',
    },
  },
  {
    path: '/team/login',
    name: 'VendorLogin',
    component: () =>
      import(/* webpackChunkName: "Login" */ '../../views/vendor/Login.vue'),
  },
  {
    path: '/team/dashboard',
    name: 'VendorDashboard',
    component: () =>
      import(
        /* webpackChunkName: "VendorDashboard" */ '../../views/vendor/Dashboard.vue'
      ),
  },
  {
    path: '/team/orderDetail',
    name: 'VendorOrderDetail',
    component: () =>
      import(
        /* webpackChunkName: "VendorOrderDetail" */ '../../views/vendor/OrderDetail.vue'
      ),
  },
];
export default vendorRoutes;
