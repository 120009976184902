import { SnackbarProgrammatic as Snackbar } from "buefy";

const Notification = {
  error: (msg, options) => {
    Snackbar.open({
      message: typeof msg === "string" ? msg : "An error occured",
      type: "is-danger",
      position: "is-bottom-right",
      duration: 3000,
      actionText: "Dismiss",
      ...options,
    });
  },
  success: (msg, options) => {
    Snackbar.open({
      message: typeof msg === "string" ? msg : "Success",
      type: "is-info",
      position: "is-bottom-right",
      duration: 3000,
      actionText: "Dismiss",
      ...options,
    });
  },
};

export default Notification;
