const websiteRoutes = [
  {
    path: "/how-we-build-brands",
    name: "BuildBrands",
    component: () =>
      import(
        /* webpackChunkName: "BuildBrands" */ "../../views/website/BuildBrands.vue"
      ),
  },
  {
    path: "/brand-research",
    name: "BrandResearch",
    component: () =>
      import(
        /* webpackChunkName: "BrandResearch" */ "../../views/website/BrandResearch.vue"
      ),
  },
  {
    path: "/case-studies",
    name: "CaseStudies",
    component: () =>
      import(
        /* webpackChunkName: "BrandResearch" */ "../../views/website/CaseStudies.vue"
      ),
  },
  {
    path: "/case-studies/tourly",
    name: "TourlyCaseStudy",
    component: () =>
      import(
        /* webpackChunkName: "BrandResearch" */ "../../views/website/caseStudies/Tourly.vue"
      ),
  },
  {
    path: "/case-studies/kite-kitchen",
    name: "KiteKitchenCaseStudy",
    component: () =>
      import(
        /* webpackChunkName: "BrandResearch" */ "../../views/website/caseStudies/KiteKitchen.vue"
      ),
  },
  {
    path: "/case-studies/roads",
    name: "RoadsCaseStudy",
    component: () =>
      import(
        /* webpackChunkName: "BrandResearch" */ "../../views/website/caseStudies/Roads.vue"
      ),
  },
  {
    path: "/case-studies/kestral",
    name: "KestralCaseStudy",
    component: () =>
      import(
        /* webpackChunkName: "BrandResearch" */ "../../views/website/caseStudies/Kestral.vue"
      ),
  },
  {
    path: "/case-studies/iinix",
    name: "IinixCaseStudy",
    component: () =>
      import(
        /* webpackChunkName: "BrandResearch" */ "../../views/website/caseStudies/Iinix.vue"
      ),
  },
  {
    path: "/faq",
    name: "FAQ",
    component: () =>
      import(
        /* webpackChunkName: "BrandResearch" */ "../../views/website/FAQ.vue"
      ),
  },
  // {
  //   path: "/pricing",
  //   name: "Pricing",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "BrandResearch" */ "../../views/website/Pricing.vue"
  //     ),
  // },
  {
    path: "/blog",
    name: "Blog",
    beforeEnter() {
      location.href = "https://blog.brandeeq.com";
    },
    component: () =>
      import(
        /* webpackChunkName: "BrandResearch" */ "../../views/website/Pricing.vue"
      ),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () =>
      import(
        /* webpackChunkName: "BrandResearch" */ "../../views/website/PrivacyPolicy.vue"
      ),
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    component: () =>
      import(
        /* webpackChunkName: "BrandResearch" */ "../../views/website/TermsAndConditions.vue"
      ),
  },
];
export default websiteRoutes;
