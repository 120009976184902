import Vue from "vue";
import Vuex from "vuex";
import api from "../services/dataService";
import router from "@/router";
import Notification from "@/services/notificationService";

Vue.use(Vuex);
function defaultState() {
  return {
    currentUser: undefined,
    allServices: undefined,
    allCustomerOrders: undefined,
    allVendorOrders: undefined,
    orderDetail: undefined,
    projects: undefined,
    allProducts: undefined,
    productDetail: undefined,
  };
}

export default new Vuex.Store({
  state: defaultState,
  mutations: {
    setStoreItem(state, payload) {
      state[payload[0]] = payload[1];
    },
  },
  actions: {
    setCurrentUser(state, request) {
      if (request) {
        request["fullName"] = request.firstName + " " + request.lastName;
      }
      state.commit("setStoreItem", ["currentUser", request]);
    },
    customerLogin(state, request) {
      return api
        .post("/customer/auth", request)
        .then((res) => {
          if (res.status === 200) {
            const user = {
              type: "customer",
              ...res.data.customer,
            };
            api.defaults.headers.common["x-auth-token"] = res.data.accessToken;
            localStorage.accessToken = res.data.accessToken;
            localStorage.user = JSON.stringify(user);
            state.dispatch("setCurrentUser", user);
            router.push({ name: "Dashboard" });
            return true;
          }
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );

          return { errorMessages: err.message };
        });
    },
    customerRegister(state, request) {
      return api
        .post("/customer/register", request)
        .then((res) => {
          if (res.status === 200) {
            const user = {
              type: "customer",
              ...res.data.customer,
            };
            api.defaults.headers.common["x-auth-token"] = res.data.accessToken;
            localStorage.accessToken = res.data.accessToken;
            localStorage.user = JSON.stringify(user);
            state.dispatch("setCurrentUser", user);
            router.push({ name: "Dashboard" });
            return true;
          }
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );

          return { errorMessages: err.message };
        });
    },

    logout(state) {
      const userType = state.state.currentUser.type || "none";
      state.dispatch("setCurrentUser", undefined);
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      api.defaults.headers.common["x-auth-token"] = undefined;
      if (userType === "vendor") {
        router.push({ name: "VendorLogin" });
      } else {
        router.push({ name: "Login" });
      }
    },
    getUserDetail(state) {
      return api.get("/users/me").then((res) => {
        if (res.data) {
          state.commit("setStoreItem", ["userDetail", res.data]);
        }
      });
    },
    getAllServices(state) {
      return api
        .get("/customer/service")
        .then((res) => {
          state.commit("setStoreItem", ["allServices", res.data]);
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getAllProducts(state) {
      return api
        .get("/customer/product")
        .then((res) => {
          state.commit("setStoreItem", ["allProducts", res.data]);
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getProductDetail(state, { id }) {
      return api
        .get("/customer/product/" + id)
        .then((res) => {
          state.commit("setStoreItem", ["productDetail", res.data]);
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    completeOrder(state, { orderId, finalSelection }) {
      return api
        .post("/customer/order/markComplete/" + orderId, {
          selectedDeliverable: finalSelection,
        })
        .then(() => {
          Notification.error("Order Completed");
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getAllCustomerOrders(state) {
      return api
        .get("/customer/order")
        .then((res) => {
          state.commit("setStoreItem", ["allCustomerOrders", res.data]);
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getOrderDetail(state, id) {
      return api
        .get("/customer/order/" + id)
        .then((res) => {
          state.commit("setStoreItem", ["orderDetail", res.data]);
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getVendorOrderDetail(state, id) {
      return api
        .get("/vendor/order/" + id)
        .then((res) => {
          state.commit("setStoreItem", ["orderDetail", res.data]);
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    createOrder(
      state,
      { paymentReferenceId, transactionId, productId, packageId }
    ) {
      return api
        .post("/customer/order", {
          paymentReferenceId,
          transactionId,
          productId,
          packageId,
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    payInvoice(state, paymentReferenceId) {
      return api
        .put("/customer/invoice/payInvoice/" + paymentReferenceId)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    submitQuestions(state, { id, request }) {
      return api
        .post("/customer/order/submitQuestions/" + id, request)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    submitResponses(state, { id, request }) {
      return api
        .post("/customer/order/submitResponses/" + id, request)
        .then(async (res) => {
          // fetching selected project from query (stored as p)
          // using it to link the created order with selected project
          await this.dispatch("assignProject", {
            orderId: res.data._id,
            projectId: router.currentRoute.query.p,
          });
          return res.data;
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    submitCustomizedService(state, request) {
      return api
        .post("/customer/customized-service/", request)
        .then(async (res) => {
          return res.data;
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getAllVendorOrders(state) {
      return api
        .get("/vendor/order")
        .then((res) => {
          state.commit("setStoreItem", ["allVendorOrders", res.data]);
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    vendorLogin(state, request) {
      return api
        .post("/vendor/auth", request)
        .then((res) => {
          if (res.status === 200) {
            const user = {
              type: "vendor",
              ...res.data.vendor,
            };
            api.defaults.headers.common["x-auth-token"] = res.data.accessToken;
            localStorage.accessToken = res.data.accessToken;
            localStorage.user = JSON.stringify(user);
            state.dispatch("setCurrentUser", user);
            router.push({ name: "VendorDashboard" });
            return true;
          }
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );

          return { errorMessages: err.message };
        });
    },
    uploadDeliverable(state, request) {
      return api
        .post("/vendor/order/uploadDeliverable", request, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {})
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message.msg
          );
        });
    },
    requestRevision(state, request) {
      return api
        .post("customer/order/requestRevision", request, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          Notification.success("Revision Submitted");
          router.go(-1);
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message.msg
          );
        });
    },
    markComplete(state, id) {
      return api
        .post("customer/order/markComplete/" + id)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    uploadResponse(state, request) {
      return api
        .post("customer/order/uploadResponse", request, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message.msg
          );
        });
    },
    submitContactForm(state, { fullName, email, message }) {
      return api
        .post("/common/contact", { fullName, email, message })
        .then(() => {
          return true;
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    getProjects(state) {
      return api
        .get("/customer/project")
        .then((res) => {
          state.commit("setStoreItem", ["projects", res.data]);
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    createProject(state, request) {
      return api
        .post("/customer/project", request)
        .then(() => {
          return true;
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    assignProject(state, { orderId, projectId }) {
      return api
        .post("/customer/order/assignProject/" + orderId, { projectId })
        .then(() => {
          return true;
        })
        .catch((err) => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
  },
  modules: {},
  getters: {},
});
