<template>
  <div class="footer-container">
    <div class="spacer"></div>
    <div class="columns mt-2 is-hidden-mobile">
      <div class="column has-text-centered">
        <img src="@/assets/img/home/tourly.svg" width="80%" />
      </div>
      <div class="column has-text-centered">
        <img src="@/assets/img/home/kite_kitchen.svg" width="80%" />
      </div>
      <div class="column has-text-centered">
        <img src="@/assets/img/home/gigtal.svg" width="80%" />
      </div>
      <div class="column has-text-centered">
        <img src="@/assets/img/home/iinix.svg" width="80%" />
      </div>
      <div class="column has-text-centered">
        <img src="@/assets/img/home/breakfast_cart.svg" width="80%" />
      </div>
      <div class="column has-text-centered">
        <img src="@/assets/img/home/roads.svg" width="80%" />
      </div>
    </div>
    <div class="spacer-small is-hidden-mobile"></div>
    <div class="columns">
      <div class="column">
        <p class="mt-4 is-gray">Contact</p>
        <div class="mt-5">
          <a href="mailto:contact@brandeeq.com">contact@brandeeq.com</a>
        </div>
        <div class="mt-5">
          Brandeeq Pvt. Ltd., 2nd Floor, Emirates Tower, M-13, F-7 Markaz,
          Islamabad
        </div>
      </div>
      <div class="column"></div>
      <div class="column">
        <p class="mt-4 is-gray">The Service</p>
        <div class="mt-5">
          <router-link :to="{ name: 'BuildBrands' }" class="is-dark-gray">
            How we build brands
          </router-link>
        </div>
        <div class="mt-5">
          <router-link :to="{ name: 'BrandResearch' }" class="is-dark-gray">
            Brand research
          </router-link>
        </div>
        <div class="mt-5">
          <router-link :to="{ name: 'Blog' }" class="is-dark-gray">
            Blog
          </router-link>
        </div>
      </div>
      <div class="column">
        <p class="mt-4 is-gray">Legal</p>
        <div v-if="false" class="mt-5">
          <router-link :to="{ name: 'Contact' }" class="is-dark-gray">
            <a href="" class="is-dark-gray">Contact</a>
          </router-link>
        </div>
        <div class="mt-5">
          <router-link
            :to="{ name: 'TermsAndConditions' }"
            class="is-dark-gray"
          >
            <a href="" class="is-dark-gray">Terms and Conditions</a>
          </router-link>
        </div>
        <div class="mt-5">
          <router-link :to="{ name: 'PrivacyPolicy' }" class="is-dark-gray">
            <a href="" class="is-dark-gray">Privacy Policy</a>
          </router-link>
        </div>
        <div class="mt-5">
          <router-link :to="{ name: 'FAQ' }" class="is-dark-gray">
            <a href="" class="is-dark-gray">FAQs</a>
          </router-link>
        </div>
      </div>
      <div class="column">
        <p class="mt-4 is-gray">Account</p>
        <div class="mt-5">
          <router-link :to="{ name: 'Login' }" class="is-dark-gray">
            Login
          </router-link>
        </div>
        <div class="mt-4">
          <router-link :to="{ name: 'Register' }" class="is-dark-gray">
            Sign up
          </router-link>
        </div>
      </div>
    </div>
    <div class="spacer"></div>
    <hr />
    <div class="columns is-vcentered is-mobile">
      <div class="column">
        <p class="text-small">© 2023 Brandeeq. All rights reserved.</p>
      </div>
      <div class="column is-narrow">
        <p class="is-subtitle is-gray">
          <a
            class="is-gray"
            href="https://www.facebook.com/profile.php?id=100083363715643"
            target="_blank"
          >
            <i class="mdi mdi-facebook mr-4"> </i>
          </a>
          <a
            class="is-gray"
            href="https://www.instagram.com/brandeeq.pk/"
            target="_blank"
          >
            <i class="mdi mdi-instagram mr-4"></i>
          </a>
          <a
            class="is-gray"
            href="https://www.linkedin.com/company/brandeeq/"
            target="_blank"
          >
            <i class="mdi mdi-linkedin mr-4"></i>
          </a>
        </p>
      </div>
    </div>
    <div class="spacer-small"></div>
    <div class="footer-pop-up has-pointer">
      <div
        class="columns is-vcentered is-mobile"
        @click="
          () => {
            this.footerPopup.expandPopup = !this.footerPopup.expandPopup;
            this.closeFooterPopup();
          }
        "
      >
        <div class="column">
          <p class="has-text-white has-text-left">FREE BRANDING GUIDE</p>
        </div>
        <div class="column is-narrow has-text-white has-text-centered">
          <i
            v-if="this.footerPopup.expandPopup"
            class="mdi mdi-chevron-down is-subtitle"
          ></i>
          <i
            v-if="!this.footerPopup.expandPopup"
            class="mdi mdi-chevron-up is-subtitle"
          ></i>
        </div>
      </div>

      <div v-if="footerPopup.expandPopup" class="has-text-left">
        <div v-if="footerPopup.stage == 0" class="box mb-5">
          <p class="has-text-dark">
            <span class="has-text-info">LIMITED TIME OFFER!</span> Save time and
            energy by using our comprehensive cheatsheet. Access it FOR FREE and
            build your brand
            <i>the right way</i>
          </p>
          <b-button
            type="is-info"
            class="mt-5"
            @click="
              () => {
                this.footerPopup.stage = 1;
              }
            "
            >GET IT NOW</b-button
          >
        </div>
        <div v-if="footerPopup.stage == 1" class="box mb-5">
          <form @submit.prevent="submitFooterPopup" id="footerPopup">
            <p>Email Address*</p>
            <b-input
              v-model="footerPopup.emailAddress"
              type="email"
              required
            ></b-input>
            <p class="mt-5">Company Name</p>
            <b-input v-model="footerPopup.companyName"></b-input>
            <p class="mt-5">Company URL</p>
            <b-input v-model="footerPopup.companyUrl"></b-input>
            <button
              form="footerPopup"
              type="submit"
              class="button is-info mt-5 has-text-white"
            >
              Get Cheatsheet
            </button>
          </form>
        </div>
        <div v-if="footerPopup.stage == 2" class="box mb-5">
          <p class="subtitle has-text-centered">All Set!</p>
          <p class="has-text-centered">
            <img src="@/assets/img/misc/login.svg" width="40%" />
            <br />
            <a
              href="https://brandeeqothers.s3.ap-south-1.amazonaws.com/Brandeeq+-+Branding+Guide+Cheatsheet.pdf"
              target="_blank"
            >
              <b-button type="is-info" class="mt-5"
                >DOWNLOAD CHEATSHEET</b-button
              ></a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      footerPopup: {
        expandPopup: true,
        stage: 0,
        emailAddress: undefined,
        companyName: undefined,
        companyUrl: undefined,
      },
    };
  },
  created() {
    if (localStorage.footerPopup && localStorage.footerPopup == "close") {
      this.footerPopup.expandPopup = false;
    }
  },
  methods: {
    async submitFooterPopup() {
      this.footerPopup.stage = 2;
      await this.$store.dispatch("submitContactForm", {
        fullName: this.footerPopup.emailAddress,
        email: this.footerPopup.emailAddress,
        message: `Company Name: ${this.footerPopup.companyName} and Comapny URL: ${this.footerPopup.companyUrl}`,
      });
    },
    closeFooterPopup() {
      if (!localStorage.footerPopup || localStorage.footerPopup != "close") {
        localStorage.footerPopup = "close";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";

.footer-container {
  padding: 0vh 10vw;
  max-width: 1980px;
  margin: 0 auto;
}
.footer-pop-up {
  position: fixed;
  bottom: 0px;
  right: 64px;
  width: 25vw;
  padding: 5px 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: $secondary-color;
}

@media only screen and (max-width: 720px) {
  .footer-pop-up {
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 100vw;
    padding: 5px 40px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    background-color: $secondary-color;
  }
}
</style>
