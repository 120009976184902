const appRoutes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "Home" */ "../../views/app/Home.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "Contact" */ "../../views/app/Contact.vue"),
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: () =>
      import(/* webpackChunkName: "Feedback" */ "../../views/app/Feedback.vue"),
  },
  {
    path: "/customized-service",
    name: "CustomizedService",
    component: () =>
      import(
        /* webpackChunkName: "CustomizedService" */ "../../views/app/CustomizedService.vue"
      ),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "Login" */ "../../views/app/Login.vue"),
  },
  {
    path: "/signup",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "Register" */ "../../views/app/Register.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(
        /* webpackChunkName: "Dashboard" */ "../../views/app/Dashboard.vue"
      ),
  },
  {
    path: "/order/detail",
    name: "OrderDetail",
    component: () =>
      import(
        /* webpackChunkName: "OrderDetail" */ "../../views/app/OrderDetail.vue"
      ),
  },
  {
    path: "/order/requestRevision",
    name: "RequestRevision",
    component: () =>
      import(
        /* webpackChunkName: "RequestRevision" */ "../../views/app/RequestRevision.vue"
      ),
  },
  {
    path: "/order/questionnaire",
    name: "OrderQuestionnaire",
    component: () =>
      import(
        /* webpackChunkName: "OrderQuestionnaire" */ "../../views/app/OrderQuestionnaire.vue"
      ),
  },
  {
    path: "/service/detail",
    name: "ProductDetail",
    component: () =>
      import(
        /* webpackChunkName: "ProductDetail" */ "../../views/app/ProductDetail.vue"
      ),
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: () =>
      import(/* webpackChunkName: "Checkout" */ "../../views/app/Checkout.vue"),
  },
];
export default appRoutes;
