<template>
  <div class="navbar">
    <b-navbar class="navbar-body py-3">
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ name: 'Home' }">
          <img src="@/assets/img/logo.svg" alt="Brandeeq" />
        </b-navbar-item>
      </template>
      <template #start>
        <b-navbar-item tag="router-link" :to="{ name: 'BuildBrands' }">
          Our Services
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'BrandResearch' }">
          Brand research
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'CaseStudies' }">
          Case Studies
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'Blog' }">
          Blog
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'FAQ' }">
          FAQs
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'Contact' }">
          Contact
        </b-navbar-item>
      </template>

      <template #end>
        <b-navbar-item v-if="currentUser && currentUser.type === 'vendor'">
          <b-tag type="is-dark">Brandeeq Team</b-tag>
        </b-navbar-item>

        <b-navbar-item
          v-if="currentUser && currentUser.type === 'customer'"
          tag="div"
        >
          <b-dropdown aria-role="list">
            <template #trigger>
              <b-button
                :label="currentUser.fullName"
                type="is-info is-light"
                icon-left="account"
                icon-right="menu-down"
              />
            </template>

            <router-link :to="{ name: 'Dashboard' }" class="is-dark-gray">
              <b-dropdown-item aria-role="listitem">
                <div class="media">
                  <b-icon class="media-left" icon="apps"></b-icon>
                  <div class="media-content">
                    <h3>Dashboard</h3>
                    <small>Manage your projects</small>
                  </div>
                </div>
              </b-dropdown-item>
            </router-link>
            <!-- <hr class="my-2" />
            <router-link :to="{ name: 'Dashboard' }" class="is-dark-gray">
              <b-dropdown-item aria-role="listitem">
                <div class="media">
                  <b-icon
                    class="media-left"
                    icon="file-multiple-outline"
                  ></b-icon>
                  <div class="media-content">
                    <h3>Invoices</h3>
                    <small>View your invoices</small>
                  </div>
                </div>
              </b-dropdown-item>
            </router-link>
            <hr class="my-2" /> -->
            <router-link :to="{ name: 'Feedback' }" class="is-dark-gray">
              <b-dropdown-item aria-role="listitem">
                <div class="media">
                  <b-icon
                    class="media-left"
                    icon="comment-quote-outline"
                  ></b-icon>
                  <div class="media-content">
                    <h3>Feedback</h3>
                    <small>Help us improve</small>
                  </div>
                </div>
              </b-dropdown-item>
            </router-link>
            <hr class="my-2" />
            <b-dropdown-item @click="logout" aria-role="listitem">
              <div class="media">
                <b-icon class="media-left" icon="logout-variant"></b-icon>
                <div class="media-content">
                  <h3>Logout</h3>
                </div>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </b-navbar-item>
        <b-navbar-item
          v-else-if="currentUser && currentUser.type === 'vendor'"
          tag="div"
        >
          <b-dropdown aria-role="list">
            <template #trigger>
              <b-button
                :label="currentUser.fullName"
                type="is-info is-light"
                icon-left="account"
                icon-right="menu-down"
              />
            </template>
            <router-link :to="{ name: 'VendorDashboard' }" class="is-dark-gray">
              <b-dropdown-item aria-role="listitem">
                <div class="media">
                  <b-icon class="media-left" icon="apps"></b-icon>
                  <div class="media-content">
                    <h3>Dashboard</h3>
                    <small>Manage your projects</small>
                  </div>
                </div>
              </b-dropdown-item>
            </router-link>
            <hr class="my-2" />
            <b-dropdown-item @click="logout" aria-role="listitem">
              <div class="media">
                <b-icon class="media-left" icon="logout-variant"></b-icon>
                <div class="media-content">
                  <h3>Logout</h3>
                </div>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </b-navbar-item>
        <b-navbar-item v-else tag="div">
          <div class="buttons">
            <router-link :to="{ name: 'Login' }" class="is-dark-gray">
              <a class="button is-info">Build your brand </a>
            </router-link>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
    <div class="navbar-offset"></div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";
</style>
